<template>
  <div class="container">
    <nav class="d-flex align-items-center mb-3 mb-lg-4" aria-label="breadcrumb">
      <router-link
        class="text-brown text-decoration-none mr-3"
        to="/loyalty-programs"
      >
        <svg
          class="mr-3"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_15_368)">
            <path
              d="M31.866 29.046C31.916 28.71 32.975 20.636 28.227 15.08C25.31 11.666 20.809 9.79703 14.997 9.61203L14.966 3.99903C14.966 3.61303 14.743 3.26203 14.395 3.09603C14.046 2.93303 13.633 2.98003 13.334 3.22403L0.366998 13.866C0.134999 14.056 -1.52259e-06 14.34 -0.00100152 14.64C-0.00200152 14.94 0.131998 15.224 0.363998 15.414L13.332 26.157C13.63 26.404 14.047 26.455 14.393 26.289C14.744 26.124 14.966 25.771 14.966 25.385L14.997 19.646C29.006 19.636 30.002 28.612 30.036 28.983C30.079 29.487 30.398 29.88 30.904 29.896C30.916 29.897 30.927 29.897 30.938 29.897C31.433 29.897 31.792 29.536 31.866 29.046ZM13.261 17.922C13.073 18.11 12.967 18.364 12.967 18.63V23.268L2.578 14.646L12.967 6.10403V10.597C12.967 11.15 13.414 11.597 13.967 11.597C19.657 11.597 24.004 13.245 26.702 16.373C28.731 18.727 29.664 21.608 29.983 23.999C27.799 21.015 23.975 17.636 13.968 17.63C13.968 17.63 13.967 17.63 13.966 17.63C13.702 17.63 13.448 17.735 13.261 17.922Z"
              fill="#6B5045"
            />
          </g>
          <defs>
            <clipPath id="clip0_15_368">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
        返回
      </router-link>
      <ol class="breadcrumb bg-transparent p-0 mb-0">
        <li class="breadcrumb-item">
          <router-link to="/">主頁</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/loyalty-programs">優惠券管理</router-link>
        </li>
        <li class="breadcrumb-item">優惠券詳情</li>
      </ol>
    </nav>

    <div class="card rounded-0 border-0 p-3 p-lg-4">
      <p class="text-danger text-right" style="color:#FFCB69">*只讀</p>
      <b-form>
        <b-row class="mb-3">
          <b-col sm="6" lg="4" class="order-2 order-lg-1">
            <div
              class="preview-image d-flex align-items-center justify-content-center text-brown border shadow-sm mb-2 mr-lg-3"
              :style="{ 'background-image': `url(${formControl.imageURL})` }"
            >
              <span v-if="!formControl.imageURL">優惠券圖片</span>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="createdDate" class="text-brown"
              >創建日期</label
            >
          </b-col>
          <b-col sm="8" lg="6">
            {{ formControl.createdDate | formatDate }}
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="titleEn" class="text-brown"
              >標題<span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="titleEn"
              class="rounded-0"
              name="titleZh"
              v-model="formControl.titleEn"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="titleZh" class="text-brown">標題 (简体版本)</label>
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="titleZh"
              class="rounded-0"
              name="titleZh"
              v-model="formControl.titleZh"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="labelZh" class="text-brown"
              >有效期限天數<span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="validityPeriodDays"
              class="rounded-0"
              name="validityPeriodDays"
              v-model="formControl.validityPeriodDays"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3" v-if="formControl.isPercentage">
          <b-col sm="4">
            <label for="couponDiscountPercentage" class="text-brown"
              >百分比 (%)<span class="text-danger"
                >*</span
              ></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="couponDiscountPercentage"
              class="rounded-0"
              name="couponDiscountPercentage"
              v-model="formControl.couponDiscountPercentage"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3" v-else>
          <b-col sm="4">
            <label for="couponDiscountValue" class="text-brown"
              >固定金額 (HDK)<span class="text-danger"
                >*</span
              ></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="couponDiscountValue"
              class="rounded-0"
              name="couponDiscountValue"
              v-model="formControl.couponDiscountValue"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="labelZh" class="text-brown"
              >Coupon threshold<span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="couponThreshold"
              class="rounded-0"
              name="couponThreshold"
              v-model="formControl.couponThreshold"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="tcEn" class="text-brown"
              >規則與條例<span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <vue-editor
              id="tcEn"
              :editorToolbar="descriptionToolbar"
              name="tcEn"
              v-model="formControl.tcEn"
              disabled
            ></vue-editor>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="tcZh" class="text-brown"
              >規則與條例 (简体版本)</label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <vue-editor
              id="tcZh"
              :editorToolbar="descriptionToolbar"
              name="tcZh"
              v-model="formControl.tcZh"
              disabled
            ></vue-editor>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="productIds" class="text-brown"
              >產品 <span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <div class="mb-4 mt-2">
              <div class="d-flex align-items-center">
                <div class="mr-2">為所有產品設定</div>
                <b-form-checkbox
                  name="check-button"
                  class="custom-switch--success"
                  switch
                  v-model="formControl.isApplicableForAll"
                  :checked="formControl.isApplicableForAll"
                  disabled
                ></b-form-checkbox>
              </div>
            </div>
            <multiselect
              v-model="formControl.productIds"
              :placeholder="
                formControl.isApplicableForAll ? '選擇全部' : '選擇'
              "
              label="nameZhHk"
              track-by="id"
              :options="categories"
              :multiple="true"
              :taggable="true"
              disabled
            ></multiselect>
            <small
              class="d-block text-danger"
              id="input-descriptionZh-live-feedback"
              v-if="noIdsSelected"
              >Please select product</small
            >
          </b-col>
        </b-row>

        <b-tabs class="tabs--form mb-4">
          <b-tab>
            <template #title>
              <div class="nav-item--text">
                描述<span class="text-danger ml-2">*</span>
              </div>
            </template>
            <b-form-textarea
              rows="3"
              max-rows="6"
              id="descriptionEn"
              class="rounded-0"
              name="descriptionEn"
              v-model="formControl.descriptionEn"
              disabled
            ></b-form-textarea>
          </b-tab>

          <b-tab>
            <template #title>
              <div class="nav-item--text">描述 (简体版本)</div>
            </template>
            <b-form-textarea
              rows="3"
              max-rows="6"
              id="descriptionZh"
              class="rounded-0"
              name="descriptionZh"
              v-model="formControl.descriptionZh"
              disabled
            ></b-form-textarea>
          </b-tab>
        </b-tabs>
      </b-form>
    </div>
  </div>
</template>

<script>
import { getAllProducts } from '@/services/product.service'
import couponService from '../../services/coupon.service'
import Multiselect from 'vue-multiselect'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'ProductCreate',
  components: { Multiselect, VueEditor },
  data: () => ({
    previewImage: null,
    descriptionToolbar: [
      ['bold', 'italic', 'underline', 'blockquote'],
      [
        { indent: '-1' },
        { indent: '+1' }
      ],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { list: 'check' }
      ],
      ['link', 'image']
    ],
    filters: {
      name: '',
      start: '',
      end: ''
    },
    categories: [],
    formControl: {
      couponDiscountPercentage: 0,
      couponDiscountValue: 0,
      isPercentage: true,
      currencyCode: 'HKD',
      descriptionEn: '',
      descriptionZh: '',
      imageURL: '',
      labelEn: '',
      labelZh: '',
      productIds: [],
      tcEn: '',
      tcZh: '',
      titleEn: '',
      titleZh: '',
      validityPeriodDays: 0,
      isApplicableForAll: false,
      couponThreshold: 0
    },
    imageFile: null,
    noIdsSelected: false
  }),

  mounted () {
    this.getCouponDetails()
  },

  methods: {
    async getCouponDetails () {
      await getAllProducts().then(
        res => {
          this.categories = res.data.data
        }
      )
      this.formControl = await couponService.getCouponById(this.$route.params.couponId)
      const products = this.categories.filter((item) => this.formControl.productIds.includes(item.id))
      this.formControl.productIds = this.formControl.isApplicableForAll ? [] : products
    }
  }
}
</script>

<style scoped lang="scss">
.preview-image {
  width: 218px;
  height: 160px;
  background-size: cover;
  background-position: center center;
}
</style>
